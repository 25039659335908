import { AppState, FeedPageMykonosAppState } from '../../../initialStateRegistration';
import {
  FeedPageMykonosMinorSections,
  FeedPageMykonosTopSection,
  FeedPageSectionNames,
} from './feedPageMykonos.initialState';

export const getFeedPage = (state: AppState) => (state as FeedPageMykonosAppState).template;

export const getSectionDataByKey = (key: FeedPageSectionNames) => (state: AppState) => getFeedPage(state)[key];

export const getSectionArticlesByKey = (key: FeedPageSectionNames) => (state: AppState) => getFeedPage(state)[key].articles;

export const getSectionTitleByKey = (key: FeedPageSectionNames) => (state: AppState) => getFeedPage(state)[key].sectionTitle;

export const getSectionSubtitleByKey = (key: FeedPageMykonosTopSection) => (state: AppState) => getFeedPage(state)[key].sectionSubtitle;

export const getShowMorePaginationTextByKey = (key: FeedPageMykonosMinorSections) => (state: AppState) => getFeedPage(state)[key].showMorePaginationText;

export const getShowMorePaginationURLByKey = (key: FeedPageMykonosMinorSections) => (state: AppState) => getFeedPage(state)[key].showMorePaginationURL;

export const getPagination = (state: AppState) => getFeedPage(state).pagination;

export const getMetadataDescription = (state: AppState) => getFeedPage(state).metadataDescription;

export const getMetadataTitle = (state: AppState) => getFeedPage(state).metadataTitle;

export const getMetadataImage = (state: AppState) => getFeedPage(state).metadataImage;

export const getHreflangData = (state: AppState) => getFeedPage(state).hreflangData;
