import React from 'react';
import { useSelector } from 'react-redux';
import {
  Ad,
  AppContent,
  baseUnit,
  DisableMainCategory,
  ErrorBoundary,
  MultiLayerBreadcrumbs,
  OneTwoOnTopFullWidth,
  PaginationButtonGroup,
  PrimarySectionTitle,
  SecondarySectionTitle,
  SectionHeader,
  SectionWithTitle,
  SixSixArticleList,
} from 'mm-ui-components';
import { Store } from 'redux';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Theme } from '../../components/connectedComponents/Theme';
import { MetaTags } from '../../components/connectedComponents/MetaTags';
import { HeadScripts } from '../../components/connectedComponents/HeadScripts';
import { SECTION_NAMES, SLOT_NAMES } from '../templates.const';
import { LoadMoreButton, useFetchNextPosts } from '../../components/connectedComponents/LoadMoreButton';
import { Page } from '../Page';
import {
  getMetadataDescription,
  getMetadataTitle,
  getSectionArticlesByKey,
  getSectionSubtitleByKey,
  getSectionTitleByKey,
  getShowMorePaginationTextByKey,
  getShowMorePaginationURLByKey,
  getMetadataImage,
  getHreflangData,
  getPagination,
} from '../../store/template/feedPage/feedPageMykonos/feedpageMykonos.selectors';
import {
  getAdSlotId,
  getAdSlotOnSuccess,
  getCanonicalUrl,
  getPropertyEndpoint,
  getUseFormatMinutesHoursAgo,
} from '../../store/config/config.selectors';
import { ErrorReporter } from '../../errorReporting/errorReporter.type';
import { AppActions, AppState } from '../../store/initialStateRegistration';
import { useMeasureHydrateTime } from '../useMeasureHydrateTime';
import { NavigationLayout } from '../../components/connectedComponents/NavigationLayout';
import { AdditionalStyleSheet, getPageMetaText } from '../templates.utils';
import { isSiProperty } from '../../store/config/config.utils';
import { createBreadcrumbsWithoutMainCategory } from '../../store/template/postPage/utils/createMainCategory.utils';

interface FeedPageMykonosProps {
  store: Store<AppState, AppActions>;
  errorProvider: ErrorReporter;
}

const getArticlesInTemplate = (state: AppState) => {
  const topSectionArticles = getSectionArticlesByKey(SECTION_NAMES.TOP_SECTION)(state) || [];
  const feedSectionArticles = getSectionArticlesByKey(SECTION_NAMES.FEED_SECTION)(state) || [];
  return [
    ...topSectionArticles,
    ...feedSectionArticles,
  ];
};

export const FeedPageMykonosComponent: React.FunctionComponent<FeedPageMykonosProps> = ({ store, errorProvider }) => {
  useMeasureHydrateTime();
  return (
    <Page store={store} errorProvider={errorProvider}>
      <ErrorBoundary>
        <FeedPageMykonosContentConnected />
      </ErrorBoundary>
    </Page>
  );
};

const createStyle = () => StyleSheet.create({
  sectionsWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridRowGap: `${baseUnit * 1.5}px`,
    marginTop: `${baseUnit * 1.5}px`,
    marginBottom: `${baseUnit * 0.5}px`,
    justifyItems: 'center',
  },
});

const breadcrumbsStyle = StyleSheet.create({
  style: {
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
    padding: `0 ${baseUnit * 0.5}px`,
  },
}) as AdditionalStyleSheet;

export const FeedPageMykonosContentConnected: React.FunctionComponent = () => {
  const topSectionDisplayName = useSelector(getSectionTitleByKey(SECTION_NAMES.TOP_SECTION));
  const topSectionArticles = useSelector(getSectionArticlesByKey(SECTION_NAMES.TOP_SECTION));
  const cardsSectionDisplayName = useSelector(getSectionTitleByKey(SECTION_NAMES.FEED_SECTION));
  const cardsSectionArticles = useSelector(getSectionArticlesByKey(SECTION_NAMES.FEED_SECTION));
  const articles = useSelector(getArticlesInTemplate);
  const topAdId = useSelector(getAdSlotId(SLOT_NAMES.TOP_AD));
  const oneOnOneId = useSelector(getAdSlotId(SLOT_NAMES.ONE_ON_ONE));
  const topAdOnSuccess = useSelector(getAdSlotOnSuccess(SLOT_NAMES.TOP_AD));
  const oneOnOneOnSuccess = useSelector(getAdSlotOnSuccess(SLOT_NAMES.ONE_ON_ONE));
  const description = useSelector(getSectionSubtitleByKey(SECTION_NAMES.TOP_SECTION));
  const metaTitle = useSelector(getMetadataTitle);
  const metaDescription = useSelector(getMetadataDescription);
  const pageImage = useSelector(getMetadataImage);
  const hreflangData = useSelector(getHreflangData) || [];
  const paginationData = useSelector(getPagination);
  const { prevButtonText, prevButtonURL, nextButtonText, nextButtonURL, currentPage } = paginationData;
  const canonicalURL = useSelector(getCanonicalUrl);
  const useFormatMinutesHoursAgo = useSelector(getUseFormatMinutesHoursAgo);
  const breadcrumbs = isSiProperty(useSelector(getPropertyEndpoint)) ? createBreadcrumbsWithoutMainCategory(canonicalURL) : null;

  const style = createStyle();
  return (
    <React.Fragment>
      <MetaTags {...getPageMetaText(metaTitle, metaDescription, currentPage)} articles={articles} pageImage={pageImage} alternateUrls={hreflangData} breadcrumbs={breadcrumbs} />
      <HeadScripts />
      <Theme>
        <DisableMainCategory>
          <AppContent>
            <NavigationLayout mainWrapperConfigThemeKey="mainContent">
              <Ad id={topAdId} onSuccess={topAdOnSuccess} setMinHeight />
              <div className={css(style.sectionsWrapper)}>
                {isSiProperty(useSelector(getPropertyEndpoint)) ? <SectionWithTitle><MultiLayerBreadcrumbs canonicalURL={canonicalURL} additionalStyle={breadcrumbsStyle} /></SectionWithTitle> : null}
                <SectionWithTitle>
                  <SectionHeader moreButtonSvg={null} moreButtonText={null} description={description}>
                    <PrimarySectionTitle>{topSectionDisplayName}</PrimarySectionTitle>
                  </SectionHeader>
                  <OneTwoOnTopFullWidth cards={topSectionArticles} useFormatMinutesHoursAgo={useFormatMinutesHoursAgo} />
                </SectionWithTitle>

                <SectionWithTitle>
                  <SectionHeader moreButtonSvg={null} moreButtonText={null}>
                    <SecondarySectionTitle>{cardsSectionDisplayName}</SecondarySectionTitle>
                  </SectionHeader>
                  <SixSixArticleList cardsProps={cardsSectionArticles} useFormatMinutesHoursAgo={useFormatMinutesHoursAgo} />
                </SectionWithTitle>

              </div>
              {Object.keys(paginationData).length === 0
                  ? <LoadMoreFeedPostsButtonConnected />
                  : <PaginationButtonGroup prevText={prevButtonText} prevURL={prevButtonURL} nextText={nextButtonText} nextURL={nextButtonURL} />}
              <Ad id={oneOnOneId} onSuccess={oneOnOneOnSuccess} />
            </NavigationLayout>
          </AppContent>
        </DisableMainCategory>
      </Theme>
    </React.Fragment>
  );
};

export const LoadMoreFeedPostsButtonConnected: React.FunctionComponent = () => {
  const sectionKey = SECTION_NAMES.FEED_SECTION;
  const shouldDisplay = !!useSelector(getShowMorePaginationURLByKey(sectionKey));
  const text = useSelector(getShowMorePaginationTextByKey(sectionKey));
  const onClick = useFetchNextPosts(sectionKey, getShowMorePaginationURLByKey);
  return <LoadMoreButton {...{ onClick, shouldDisplay, text }} />;
};
